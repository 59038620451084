export default function getPlotType(plotId: number, towns: number[], claimed: number[], available: number[]): string {
    if (towns.includes(plotId)) {
        return 'town';
    } else if (claimed.includes(plotId)) {
        return 'claimed';
    } else if (available.includes(plotId)) {
        return 'available';
    } else {
        return 'locked';
    }
}

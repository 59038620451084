export const HomeBody1 = `
<center><h1>A space to trade, organize, & build. <br/> A place to explore. A launching pad for adventure </h1></center>
MARZ PLOTS are a minimalist spatial framework for the Loot universe. 

Plots are minted and stored on-chain. Plot properties, usage, or creatures remain for others to interpret and build as a separate layer.

<center><h1>

Join us on [twitter](https://twitter.com/marzplots). Find us on [etherscan](https://etherscan.io/address/0xd0ba8b19b0f5e25c11ed233302e75794c9d3142b).

Chat on [discord](https://discord.gg/GDytXJpg). Trade on [opensea](https://opensea.io/collection/plots-on-marz).

</h1></center>

## Distribution & Minting
There are 20,000 plots on a 200\\*100 grid. Each plot is 10\\*10 pixels.

Plots can only be minted adjacent to already minted plots.

9 locations have pre-minted "outpost towns" corresponding to NASA's 9 landing sites: 
- Viking1 (1976)
- Viking2 (1976)
- Pathfinder (1997)
- Spirit (2004)
- Opportunity (2004)
- Phoenix (2008)
- Curiosity  (2012)
- Insight (2018)
- Perseverance (2021)

8,000 plots reserved for Loot holders—one plot per loot bag. These are free, and can be minted on Etherscan via the "claimWithLoot" function.

9 plots minted for outpost towns

1,991 plots open for minting by anyone for price of gas

10,000 plots open for minting by anyone for 0.025Ξ


## Roadmap
PLOTS provide the barest spatial interpretation for a metaverse, which disaggregates and open-sources the rules for how plots can be used or relate to each other.
MARZ is merely the map we have chosen to give it spatial order and meaning, an example of how to use plots to build a world. 

Other members of the Loot community can quickly create new maps to reshuffle the locations of towns and plots, enabling different types of play. And we're happy to help!

Potential card systems and layers to build on plots:
- natural endowment cards, generated synthetically for each plot
- resource rules, which allow users to lock up different types of endowment cards in-game and receive resources (metals, timber, food)
- building rules and layer, which allow users to place different types of structures on plots in-game in exchange for locking up resources and AGLD
- wildlife cards, generated synthetically and which could represent the magical fauna and flora living on each plot
- usage rules, which allow users to "rent" plots for use in-game

The distribution and features of these rules and card systems is that they could be as varied as the Loot games that are being built.


## Contributors
PLOTS and the MARZ map are a Prometheus Research Labs project. Our code is open-source.

We'd love to help you build using MARZ PLOTS! Reach us at: hello at prl.one !

## Derivatives
[MarzMining](https://marzmining.xyz) - Mine resources on Marz!

`;

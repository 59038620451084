import { gql } from '@apollo/client';

export interface TokenData {
  tokens: Token[];
}

export interface Token {
  id: string;
  owner: {
    id: string;
  };
}

export const GET_TOKENS = gql`
  query getBonds($lastId: ID!) {
    tokens(first: 1000, where: { id_gt: $lastId }) {
      id
      owner {
        id
      }
    }
  }
`;

import React, {useState} from 'react';
import {Box, Button, createStyles, Dialog, makeStyles, TextField, Theme} from "@material-ui/core";
import interactiveMap from "./GridMap/interactiveMap";

const useStyles = makeStyles((inputTheme: Theme) => createStyles({
  root: {
    borderRadius: 20,
    padding: '20px 40px 20px 40px',
    background: 'linear-gradient( -140deg, #2c2c2c 0%, #111 100%)',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    alignItems: 'center',
    color: 'white',
    textAlign: 'center'
  },
  claimButton: {
    flexGrow: 1,
    width: '100%',
    margin: 6,
    borderRadius: 12,
    backgroundColor: '#7AC143'
  },
  redeemButton: {
    flex: 1,
    flexGrow: 1,
    margin: 6,
    borderRadius: 12,
    backgroundColor: 'blue'
  },
  label: {
    fontFamily: "'Press Start 2P'",
    marginLeft: 6,
    marginRight: 6
  },
  redeemPrompt: {
    color: 'blue',
    margin: 6,
  },
  image: {
    margin: 10,
    imageRendering: 'pixelated', //crisp-edges doesn't work on chrome?
    border: 'solid 2px',
  },
  textField: {
    flex: 2,
    borderColor: 'white',
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
        borderColor: 'white',
      }
    }
  }
}));

interface PurchaseModalProps {
  /**
   * plotId
   */
  plotId: number;
  /**
   * Open
   */
  open: boolean;
  /**
   * callback to claim plot
   */
  claimCallback: (plotId: number) => void;
  /**
   * callback to redeem plot as a lootholder
   */
  redeemCallback: (plotId: number, lootId: string) => void;
  /**
  /**
   * callback to close dialog
   */
  closeCallback: () => void;
}

export function PurchaseModal ({ plotId, open, claimCallback, redeemCallback, closeCallback }: PurchaseModalProps) {
  const classes = useStyles();
  const [lootId, setLootId] = useState<string>('')

  return (
    <Dialog
      open={open}
      onClose={() => closeCallback()}
      scroll={'body'}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      Purchase Plot #{plotId}?
      <img className={classes.image} src={interactiveMap.getPlotImageData(plotId)} width={100} height={100} alt={"Mars Token Preview"} style={{borderColor: 'green'}}/>
      <Button
        classes={{root: classes.claimButton, label: classes.label}}
        onClick={() => {
          claimCallback(plotId);
          closeCallback();
        }}
      >
        Claim!
      </Button>
      OR
      <div className={classes.redeemPrompt}>
        Redeem With Loot:
      </div>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
      >
        <TextField
          id="lootId"
          type="number"
          variant="outlined"
          className={classes.textField}
          onChange={(event) => setLootId(event.target.value)}
        />
        <Button
          classes={{root: classes.redeemButton, label: classes.label}}
          onClick={() => {
            redeemCallback(plotId, lootId);
            closeCallback();
          }}
        >
          Redeem!
        </Button>
      </Box>
    </Box>
    </Dialog>
  );
}

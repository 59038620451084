import { gql } from '@apollo/client';

export interface ContractData {
  contract: Contract;
}

export interface Contract {
  id: string;
  totalSupply: string;
  publicPlotsAvailable: string;
}

export const GET_CONTRACT = gql`
  query getContract($id: ID!) {
    contract(id: $id) {
      id
      totalSupply
      publicPlotsAvailable
    }
  }
`;

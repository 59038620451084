export default function neighbors(tileId: number, rows: number, columns: number) {
    const above = (tileId >= columns);
    const below = (tileId <= (rows - 1) * columns);
    const left = (tileId % columns > 0);
    const right = (tileId % columns < columns - 1);
    return ([
        above && left && (tileId - 1 - columns),
        above && (tileId - columns),
        above && right && (tileId + 1 - columns),
        left && (tileId - 1),
        right && (tileId + 1),
        below && left && (tileId - 1 + columns),
        below && (tileId + columns),
        below && right && (tileId + 1 + columns)
    ] as number[]).filter(x => x !== null)
}
import React from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((inputTheme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 5,
        border: 'solid 2px',
        padding: 10,
        background: 'linear-gradient( -140deg, #2c2c2c 0%, #111 100%)',
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
        },
        // backgroundColor: 'yellow',
        position: 'absolute',
        color: 'white',
        pointerEvents: 'none'
    },
    image: {
        margin: 10,
        imageRendering: 'pixelated', //crisp-edges doesn't work on chrome?
        border: 'solid 2px',
    }
}));

export interface TooltipProps {
    tooltipOffsetXType: 'left' | 'right';
    tooltipOffsetYType: 'top' | 'bottom';
    offsetX: number;
    offsetY: number;
    /**
     * Width & Height of Image Preview
     */
    size: number;
    /**
     * Plot Id
     */
    plotId: number;
    /**
     * Plot Type
     */
    plotType: string;
    /**
     * Image Src
     */
    imageSrc: string;
    /**
     * style
     */
    style?: React.CSSProperties;
}

const borderColorMap = new Map([
    ['town', 'gold'],
    ['claimed', 'white'],
    ['available', '#7AC143'],
    ['locked', 'red']
]);

const titleMap = new Map([
    ['town', '[TOWN]'],
    ['claimed', 'Plot ID:'],
    ['available', 'Plot ID:'],
    ['locked', '[LOCKED]']
]);

const townNameMap = new Map([
    [10376, 'Curiosity'],
    [2030, 'Phoenix'],
    [7473, 'Viking 1'],
    [7881, 'Pathfinder'],
    [10296, 'Opportunity'],
    [4374, 'Viking 2'],
    [9575, 'Insight'],
    [7943, 'Perseverance'],
    [11797, 'Spirit']
]);

export const Tooltip = React.memo(({
    tooltipOffsetXType,
    tooltipOffsetYType,
    offsetX,
    offsetY,
    size,
    plotId,
    plotType,
    imageSrc,
    style
}: TooltipProps) => {
    const classes = useStyles();

    const borderColor = borderColorMap.get(plotType);
    const dynamicStyle = {
        [tooltipOffsetXType]: offsetX,
        [tooltipOffsetYType]: offsetY,
        borderColor: borderColor,
        color: borderColor,
        fontFamily: 'Press Start 2P'
    };

    return (
        <div className={classes.root}
             style={dynamicStyle}>
            {(plotType === 'town') ? townNameMap.get(plotId) : titleMap.get(plotType)}
            <br/>
            #{plotId}
            <img className={classes.image} src={imageSrc} width={size} height={size} alt={"Mars Token Preview"}
                 style={{borderColor: borderColor}}/>
        </div>
    );
});

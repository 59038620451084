import {Box, createStyles, Dialog, makeStyles, Theme} from "@material-ui/core";
import Loader from 'react-loader-spinner';
import interactiveMap from "./GridMap/interactiveMap";

const useStyles = makeStyles((inputTheme: Theme) => createStyles({
  root: {
    borderRadius: 20,
    padding: '20px 40px 20px 40px',
    background: 'linear-gradient( -140deg, #2c2c2c 0%, #111 100%)',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    alignItems: 'center',
    color: 'white',
    textAlign: 'center'
  },
  label: {
    fontFamily: "'Press Start 2P'",
    marginLeft: 6,
    marginRight: 6
  },
  openseaLink: {
    color: 'blue',
    margin: 6,
  },
  image: {
    margin: 10,
    imageRendering: 'pixelated',
    border: 'solid 2px',
  },
  textField: {
    flex: 2,
    borderColor: 'white',
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
        borderColor: 'white',
      }
    }
  }
}));

export interface SuccessModalProps {
  /**
   * plotId
   */
  plotId: number;
  /**
   * Open
   */
  open: boolean;
  /**
   * Open
   */
  loading: boolean;
  /**
   * callback to close dialog
   */
  closeCallback: () => void;
}

export function SuccessModal ({ plotId, open, loading, closeCallback }: SuccessModalProps) {
  const classes = useStyles();
  const openseaLink = `https://opensea.io/assets/0xd0ba8b19b0f5e25c11ed233302e75794c9d3142b/${plotId}`;

  return (
    <Dialog
      open={open}
      onClose={() => closeCallback()}
      scroll={'body'}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >

    {loading ? 
    <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
    >
        Waiting for transaction confirmation...
        <br/>
        <Loader
            type="Rings"
            color="#00BFFF"
        />
    </Box>
        :
        <Box
            className={classes.root}
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
        >
            Successfully claimed Plot #{plotId}!
            <img className={classes.image} src={interactiveMap.getPlotImageData(plotId)} width={100} height={100} alt={"Mars Token Preview"} style={{borderColor: 'green'}}/>
            <div className={classes.openseaLink}>
                <a href={openseaLink}>View on OpenSea</a>
            </div>
        </Box>
    }
</Dialog>
  );
}

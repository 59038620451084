import getPlotId from "./getPlotId";
import mapConfig from "./mapConfig";

export interface Plot {
    plotId: number;
    plotType: string;
    x: number;
    y: number;
}

export interface Coordinates {
    x: number,
    y: number
}

const {
    columnCount,
    rowCount,
    plotWidth,
    plotHeight,
    gridlineWidth
} = mapConfig;

class InteractiveMap {
    private _width: number
    private _height: number
    public canvas: HTMLCanvasElement
    private _ctx: CanvasRenderingContext2D
    private _canvasBase: HTMLCanvasElement
    private _ctxBase: CanvasRenderingContext2D
    private _canvasOutline: HTMLCanvasElement
    private _ctxOutline: CanvasRenderingContext2D
    private _canvasCrop: HTMLCanvasElement
    private _ctxCrop: CanvasRenderingContext2D
    private _backgroundImage: HTMLImageElement
    private _isImageLoaded: boolean
    private _isDirty: boolean
    private _mouseEventsEnabled: boolean
    private _plots: Plot[]
    private _selectedX: number
    private _selectedY: number
    private _showSelected: boolean
    private _plotIdToImageDataMap: Map<number, string>
    public setSelectedPlotCoords: ((selectedPlotCoords: Coordinates) => void) | null
    public setSetShowTooltip: ((showTooltip: boolean) => void) | null
    public claimHandler: ((plotId: number) => void) | null
    private _boundOnMouseMove: (e: MouseEvent) => void
    private _boundOnMouseEnter: () => void
    private _boundOnMouseLeave: () => void
    private _boundOnMouseDown: () => void
    private _boundDraw: () => void

    constructor() {
        this._width = (columnCount * plotWidth) + ((columnCount + 1) * gridlineWidth);
        this._height = (rowCount * plotHeight) + ((rowCount + 1) * gridlineWidth);

        // Main canvas that's shown to use
        this.canvas = document.createElement('canvas');
        this.canvas.style.width = '100%';
        this.canvas.width = this._width;
        this.canvas.height = this._height;
        this._ctx = this.canvas.getContext('2d')!;
        // Offscreen canvas used to assemble the fully opaque base with map and gridlines
        this._canvasBase = document.createElement('canvas');
        this._canvasBase.width = this._width;
        this._canvasBase.height = this._height;
        this._ctxBase = this._canvasBase.getContext('2d')!;
        // Offscreen canvas used to draw and store a green outline for an available or claimed plot
        this._canvasOutline = document.createElement('canvas');
        this._canvasOutline.width = plotWidth + 2;
        this._canvasOutline.height = plotHeight + 2;
        this._ctxOutline = this._canvasOutline.getContext('2d')!;
        // Offscreen canvas used to extract the tile image data used by the hover preview
        this._canvasCrop = document.createElement('canvas');
        this._canvasCrop.width = plotWidth;
        this._canvasCrop.height = plotHeight;
        this._ctxCrop = this._canvasCrop.getContext('2d')!;
        // The map image
        this._backgroundImage = document.createElement('img');
        this._backgroundImage.src = 'map.jpg';

        this._isImageLoaded = false;
        this._isDirty = true;
        this._mouseEventsEnabled = false;
        this._plots = [];
        this._selectedX = 0;
        this._selectedY = 0;
        this._showSelected = false;
        this._plotIdToImageDataMap = new Map();

        this.setSelectedPlotCoords = null;
        this.setSetShowTooltip = null;
        this.claimHandler = null;
        this._boundOnMouseMove = this._onMouseMove.bind(this);
        this._boundOnMouseEnter = this._onMouseEnter.bind(this);
        this._boundOnMouseLeave = this._onMouseLeave.bind(this);
        this._boundOnMouseDown = this._onMouseDown.bind(this);
        this._boundDraw = this._draw.bind(this);

        this.canvas.addEventListener('mousemove', this._boundOnMouseMove);
        this.canvas.addEventListener('mouseenter', this._boundOnMouseEnter);
        this.canvas.addEventListener('mouseleave', this._boundOnMouseLeave);
        this.canvas.addEventListener('mousedown', this._boundOnMouseDown);

        this._initCanvasBase();
        this._initCanvasOutline();
        this._draw();
    }

    updatePlots(plots: Plot[]) {
        this._plots = plots;
        this._isDirty = true;
    }

    getPlotImageData(plotId: number) {
        let imageData;
        if (this._isImageLoaded) {
            if (!this._plotIdToImageDataMap.get(plotId)) {
                const canvas = this._canvasCrop;
                const ctx = this._ctxCrop;
                const x = plotId % columnCount;
                const y = Math.floor(plotId / columnCount);
                const sx = x * plotWidth;
                const sy = y * plotHeight;
                ctx.drawImage(this._backgroundImage, sx, sy, plotWidth, plotHeight, 0, 0, plotWidth, plotHeight);
                this._plotIdToImageDataMap.set(getPlotId(x, y), canvas.toDataURL());
            }
            imageData = this._plotIdToImageDataMap.get(plotId);
        }
        return imageData || 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
    }

    enableMouseEvents() {
        this._mouseEventsEnabled = true;
        if (this.setSelectedPlotCoords) {
            this.setSelectedPlotCoords({x: this._selectedX, y: this._selectedY});
        }
        if (this.setSetShowTooltip) {
            this.setSetShowTooltip(this._showSelected);
        }
        if (this._showSelected) {
            this._isDirty = true;
        }
    }

    disableMouseEvents() {
        this._mouseEventsEnabled = false;
        if (this._showSelected) {
            this._isDirty = true;
        }
    }

    _initCanvasBase() {
        const ctx = this._ctxBase;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, this._width, this._height);
        this._backgroundImage.onload = () => {
            this._isImageLoaded = true;
            for (let y = 0; y < rowCount; y++) {
                for (let x = 0; x < columnCount; x++) {
                    const sx = x * plotWidth;
                    const sy = y * plotHeight;
                    const dx = 1 + (x * (gridlineWidth + plotWidth));
                    const dy = 1 + (y * (gridlineWidth + plotHeight));
                    ctx.drawImage(this._backgroundImage, sx, sy, plotWidth, plotHeight, dx, dy, plotWidth, plotHeight);
                }
            }
            this._isDirty = true;
        }
    }

    _initCanvasOutline() {
        const ctx = this._ctxOutline;
        // After half plots claimed it would be more efficient to have green outline on base canvas and white overlay
        // Can just switch the code around if necessary when we get there
        ctx.fillStyle = '#7AC143';
        ctx.fillRect(0, 0, this._canvasOutline.width, this._canvasOutline.height);
        ctx.clearRect(1, 1, plotWidth, plotHeight);
    }

    _onMouseMove(e: MouseEvent) {
        const x = Math.floor(columnCount * e.offsetX / this.canvas.clientWidth);
        const y = Math.floor(rowCount * e.offsetY / this.canvas.clientHeight);
        this._selectedX = x;
        this._selectedY = y;
        this._showSelected = true;
        this._isDirty = true;
        if (this._mouseEventsEnabled && this.setSelectedPlotCoords) {
            this.setSelectedPlotCoords({x, y});
        }
    }

    _onMouseEnter() {
        this._showSelected = true;
        this._isDirty = true;
        if (this._mouseEventsEnabled && this.setSetShowTooltip) {
            this.setSetShowTooltip(this._showSelected);
        }
    }

    _onMouseLeave() {
        this._showSelected = false;
        this._isDirty = true;
        if (this._mouseEventsEnabled && this.setSetShowTooltip) {
            this.setSetShowTooltip(this._showSelected);
        }
    }

    _onMouseDown() {
        if (this._mouseEventsEnabled && this.claimHandler) {
            this.claimHandler(getPlotId(this._selectedX, this._selectedY));
        }
    }

    _draw() {
        if (this._isDirty) {
            this._isDirty = false;
            const ctx = this._ctx;
            // Clear canvas by drawing base image over the top
            ctx.globalAlpha = 1;
            ctx.drawImage(this._canvasBase, 0, 0, this._width, this._height);

            const selectedX = this._selectedX;
            const selectedY = this._selectedY;

            // Draw each plot overlay, styled as appropriate
            for (const plot of this._plots) {
                const {x, y, plotType} = plot;
                const dx = 1 + (x * (gridlineWidth + plotWidth));
                const dy = 1 + (y * (gridlineWidth + plotHeight));
                if (plotType !== 'locked') {
                    ctx.globalAlpha = 1;
                    ctx.drawImage(this._canvasOutline, dx - 1, dy - 1, this._canvasOutline.width, this._canvasOutline.height);
                }
                if (this._mouseEventsEnabled && this._showSelected && x === selectedX && y === selectedY) {
                    ctx.fillStyle = 'red';
                    ctx.globalAlpha = 1;
                } else {
                    switch (plotType) {
                        case 'town':
                            ctx.fillStyle = 'gold';
                            ctx.globalAlpha = 0.5;
                            break;
                        case 'claimed':
                            ctx.globalAlpha = 0;
                            break;
                        case 'available':
                            ctx.fillStyle = '#7AC143';
                            ctx.globalAlpha = 0.5;
                            break;
                        case 'locked':
                            ctx.fillStyle = 'white';
                            ctx.globalAlpha = 0.95;
                            break;
                        default:
                            throw new Error(`Unrecognised plotType: ${plotType}`);
                    }
                }
                if (ctx.globalAlpha > 0) {
                    ctx.fillRect(dx, dy, plotWidth, plotHeight);
                }
            }
        }
        window.requestAnimationFrame(this._boundDraw);
    }
}

const interactiveMap = new InteractiveMap();

export default interactiveMap;

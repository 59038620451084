const townNameMap = new Map([
    [10376, 'Curiosity'],
    [2030, 'Phoenix'],
    [7473, 'Viking 1'],
    [7881, 'Pathfinder'],
    [10296, 'Opportunity'],
    [4374, 'Viking 2'],
    [9575, 'Insight'],
    [7943, 'Perseverance'],
    [11797, 'Spirit']
]);

const mapConfig = {
    columnCount: 200,
    rowCount: 100,
    plotWidth: 10,
    plotHeight: 10,
    gridlineWidth: 1,
    TOWNS: Array.from(townNameMap.keys()),
    townNameMap
}

export default mapConfig;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {CircularProgress, createStyles, makeStyles, Theme} from "@material-ui/core";
import interactiveMap, {Coordinates, Plot} from "./interactiveMap";
import {Tooltip} from "./Tooltip";
import mapConfig from "./mapConfig";
import getPlotId from "./getPlotId";
import getPlotType from "./getPlotType";
import {getAvailable} from "./getAvailable";

export interface GridMapProps {
    enableMouseEvents: boolean;
    /**
     * Claimed Tiles
     */
    claimed: number[];
    /**
     * Handler to claim plot
     */
    claimHandler: (plotId: number) => void;
    /**
     * style
     */
    style?: React.CSSProperties;
}

export interface PlotProps {
    plotId: number;
    plotType: string;
    x: number;
    y: number;
}

const useStyles = makeStyles((inputTheme: Theme) => createStyles({
    root: {
        borderRadius: 20,
        padding: 20,
        background: 'linear-gradient( -140deg, #2c2c2c 0%, #111 100%)',
        '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
        },
        position: 'relative'
    },
    spinner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
    }
}));

const {columnCount, rowCount, TOWNS} = mapConfig;

const plotsBase: Plot[] = [];
for (let y = 0; y < rowCount; y++) {
    for (let x = 0; x < columnCount; x++) {
        const plotId = getPlotId(x, y);
        plotsBase.push({
            plotId,
            plotType: 'locked',
            x,
            y
        });
    }
}

export const GridMap = ({claimed, claimHandler, enableMouseEvents}: GridMapProps) => {
    const classes = useStyles();
    const rendererParent = useRef(null);
    const [selectedPlotCoords, setSelectedPlotCoords] = useState<Coordinates>({x: 0, y: 0});
    const [showTooltip, setSetShowTooltip] = useState<boolean>(false);
    const available = useMemo(() => {
        return getAvailable(TOWNS.concat(claimed), rowCount, columnCount);
    }, [claimed]);
    useMemo(() => {
        const newPlots = plotsBase.map(({plotId, x, y}) => {
            // Not the neatest way of doing it but this keeps the map fully fogged until all data has loaded
            const plotType = claimed.length ? getPlotType(plotId, TOWNS, claimed, available) : 'locked';
            return {
                plotId,
                plotType,
                x,
                y
            }
        });
        interactiveMap.updatePlots(newPlots);
        return newPlots;
    }, [claimed, available]);

    const selectedPlotId = getPlotId(selectedPlotCoords.x, selectedPlotCoords.y)
    const selectedPlotType = getPlotType(selectedPlotId, TOWNS, claimed, available);

    useEffect(() => {
        if (rendererParent !== null && rendererParent.current !== null) {
            const current = rendererParent.current;
            // TODO figure out why typescript is bitching about this
            // @ts-ignore
            current.appendChild(interactiveMap.canvas);
            interactiveMap.setSelectedPlotCoords = setSelectedPlotCoords;
            interactiveMap.setSetShowTooltip = setSetShowTooltip;
            interactiveMap.claimHandler = (plotId) => {
                if (available.includes(plotId)) {
                    claimHandler(plotId);
                }
            };
            if (enableMouseEvents) {
                interactiveMap.enableMouseEvents();
            } else {
                interactiveMap.disableMouseEvents();
            }
            return () => {
                // TODO figure out why typescript is bitching about this
                // @ts-ignore
                current.removeChild(interactiveMap.canvas);
                interactiveMap.setSelectedPlotCoords = null;
                interactiveMap.setSetShowTooltip = null;
                interactiveMap.claimHandler = null;
            };
        } else {
            return () => {
            };
        }
    }, [rendererParent, available, claimHandler, enableMouseEvents]);

    const tooltipOffset = 30;
    const xFrac = selectedPlotCoords.x / columnCount;
    const yFrac = selectedPlotCoords.y / rowCount;
    const tooltipOffsetXType = xFrac > 0.5 ? 'right' : 'left';
    const tooltipOffsetYType = yFrac > 0.5 ? 'bottom' : 'top';
    const tooltipX = (interactiveMap.canvas.clientWidth * (tooltipOffsetXType === 'left' ? xFrac : 1 - xFrac)) + tooltipOffset;
    const tooltipY = (interactiveMap.canvas.clientHeight * (tooltipOffsetYType === 'top' ? yFrac : 1 - yFrac)) + tooltipOffset;

    return (
        <div className={classes.root} ref={rendererParent}>
            {!claimed.length && <CircularProgress className={classes.spinner} color={'secondary'}/>}
            {showTooltip &&
			<Tooltip
				tooltipOffsetXType={tooltipOffsetXType}
				tooltipOffsetYType={tooltipOffsetYType}
				offsetX={tooltipX}
				offsetY={tooltipY}
				size={100}
				plotId={selectedPlotId}
				plotType={selectedPlotType}
				imageSrc={(selectedPlotType === 'locked') ? `tiles/map_tile_loading.jpg` : interactiveMap.getPlotImageData(selectedPlotId)}
			/>
            }
        </div>
    );
};

import {useCallback, useContext, useEffect, useState} from 'react';
import {Signer} from 'ethers';
import rehypeRaw from 'rehype-raw'
import {useMediaQuery} from 'react-responsive';
import {Button, createStyles, makeStyles, Theme} from "@material-ui/core";
import {QueryResult, useQuery} from "@apollo/client";
import {ReactMarkdown} from "react-markdown/lib/react-markdown";
import Web3Context from '../contexts/Web3Context';
import {HomeBody1} from '../markdown/Home';
import {claim, claimWithLoot} from '../transactions';
import {GridMap} from "../components/GridMap/GridMap";
import {PurchaseModal} from "../components/PurchaseModal";
import {SuccessModal} from "../components/SuccessModal";
import {getAvailable} from "../components/GridMap/getAvailable";
import mapConfig from "../components/GridMap/mapConfig";
import {GET_TOKENS, TokenData} from "../queries/token";
import {ContractData, GET_CONTRACT} from '../queries/contract';

const contractAddress = '0xd0ba8b19b0f5e25c11ed233302e75794c9d3142b';

const useStyles = makeStyles((inputTheme: Theme) =>
    createStyles({
        root: {
            paddingRight: 120,
            paddingLeft: 120,
            paddingBottom: 24,
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            backgroundColor: inputTheme.palette.background.default
        },
        rootSmallScreen: {
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 24,
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            backgroundColor: inputTheme.palette.background.default
        },
        textBody: {
            textAlign: 'left',
            lineHeight: 1.6
        },
        textBodySmallScreen: {
            textAlign: 'left',
            lineHeight: 1.6,
            fontSize: '0.7rem'
        },
        label: {
            fontFamily: "'Press Start 2P'",
            marginLeft: 6,
            marginRight: 6
        },
        claimButton: {
            height: '50%',
            margin: 6,
            borderRadius: 12,
            backgroundColor: '#7AC143'
        },
        centeredText: {
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'left',
            lineHeight: 1.6
        },
        leftText: {
            textAlign: 'left',
            lineHeight: 1.6,
            display: 'flex',
            flex: 1
        },
        filler: {
            flex: 1,
            lineHeight: 1.6
        },
        subtitle: {
            display: 'flex',
            alignSelf: 'stretch'
        }
    })
);

function plotIdDecToHex(plotId: number) {
    return '0x' + plotId.toString(16);
}

function getRandomAvailablePlot(claimed: number[]): number {
    const {columnCount, rowCount, TOWNS} = mapConfig;
    const available = getAvailable(TOWNS.concat(claimed), rowCount, columnCount).filter((claimed) => !!claimed);
    return available[Math.floor(Math.random() * available.length)];
}

function claimPlot(
    signer: Signer | undefined,
    marzAddress: string | undefined,
    plotId: string | undefined,
    claimCallback: (claimedPlot: string) => void
): boolean {
    if (!signer) {
        alert('Connect Wallet');
        return false;
    }

    if (!marzAddress) {
        alert('No Marz contract found');
        return false;
    }

    if (!plotId) {
        alert('No plot selected');
        return false;
    }

    claim(marzAddress, signer, plotId).then(
        (output) => {
            if (output) {
                claimCallback(plotId);
            }
        },
        (error) => {
            console.log(error);
        }
    );
    return true;
}

function claimPlotWithLoot(
    signer: Signer | undefined,
    marzAddress: string | undefined,
    plotId: string | undefined,
    lootId: string | undefined,
    claimCallback: (claimedPlot: string) => void
): boolean {
    if (!signer) {
        alert('Connect Metamask');
        return false;
    }

    if (!marzAddress) {
        alert('No Marz contract found');
        return false;
    }

    if (!plotId) {
        alert('No plot selected');
        return false;
    }

    if (!lootId) {
        alert('No loot ID given');
        return false;
    }

    claimWithLoot(marzAddress, signer, plotId, lootId).then(
        (output) => {
            if (output) {
                claimCallback(plotId);
            }
        },
        (error) => {
            console.log(error);
        }
    );
    return true;
}

function Home() {
    const classes = useStyles();
    const [lastId, setLastId] = useState('');
    const [inInitialLoad, setInInitialLoad] = useState(true);
    const [publicPlotsAvailable, setPublicPlotsAvailable] = useState<string>('');
    const [claimed, setClaimed] = useState<number[]>([]);
    const [successPlot, setSuccessPlot] = useState<number | null>(null);
    const {signer} = useContext(Web3Context);
    const [txProcessing, setTxProcessing] = useState<boolean>(false);
    const [purchasePlot, setPurchasePlot] = useState<number | null>(null);
    const isBigScreen = useMediaQuery({query: '(min-width: 1824px)'});

    const contractQuery: QueryResult<ContractData> = useQuery<ContractData>(GET_CONTRACT, {
        variables: {id: contractAddress}
    });
    const tokensQuery: QueryResult<TokenData> = useQuery<TokenData>(GET_TOKENS, {
        variables: {lastId: lastId}
    });

    // TODO: rerender chart after the transaction confirms
    // and subgraph updates
    // const claimHandler = useCallback((plotId) => {
    //   console.log("Hello!")
    //     setPurchasePlot(plotId)
    //     // claimPlot(signer, data?.tokens[0].contract.id, plotId, (claimedPlot) => {
    //     //     console.log(claimedPlot);
    //     //     setClaimed(claimed.concat(['0x' + parseInt(claimedPlot).toString(16)]));
    //     // });
    // }
    //   , [data, signer, claimed, purchasePlot]);

    const claimCallback = useCallback(
        (plotId) => {
            const success = claimPlot(signer, contractAddress, plotId.toString(), (claimedPlot) => {
                setTxProcessing(false);
                setClaimed(claimed.concat([plotId]));
            });
            if (success) {
                setTxProcessing(true);
                setSuccessPlot(plotId);
            }
        },
        [signer, claimed]
    );

    const redeemCallback = useCallback(
        (plotId, lootId) => {
            const success = claimPlotWithLoot(
                signer,
                contractAddress,
                plotId.toString(),
                lootId.toString(),
                () => {
                    setTxProcessing(false);
                    setClaimed(claimed.concat([plotId]));
                }
            );
            if (success) {
                setTxProcessing(true);
                setSuccessPlot(plotId);
            }
        },
        [signer, claimed]
    );

    useEffect(() => {
        if (tokensQuery.data) {
            if (tokensQuery.data.tokens.length) {
                const nextClaimed = tokensQuery.data.tokens.map((token) => parseInt(token.id, 16));
                setClaimed(claimed.concat(nextClaimed));
                setLastId(plotIdDecToHex(nextClaimed[nextClaimed.length - 1]));
            } else {
                setInInitialLoad(false);
            }
        }
        if (contractQuery.data) {
            setPublicPlotsAvailable(contractQuery.data.contract.publicPlotsAvailable);
        }
    }, [tokensQuery.data, contractQuery.data, claimed, lastId]);

    return (
        <div className={isBigScreen ? classes.root : classes.rootSmallScreen}>
            <PurchaseModal
                plotId={purchasePlot || -1}
                open={purchasePlot !== null}
                claimCallback={claimCallback}
                redeemCallback={redeemCallback}
                closeCallback={() => setPurchasePlot(null)}
            />
            <SuccessModal
                plotId={successPlot || -1}
                open={successPlot !== null}
                closeCallback={() => setSuccessPlot(null)}
                loading={txProcessing}
            />
            <GridMap
                claimed={inInitialLoad ? [] : claimed}
                claimHandler={setPurchasePlot}
                enableMouseEvents={!inInitialLoad}
            />
            <div className={classes.subtitle}>
                {isBigScreen && (
                    <div className={classes.leftText}>
                        Legend
                        <br/>
                        Green: Claimable
                        <br/>
                        White: Unclaimable
                        <br/>
                        Gold: Landing Site
                        <br/>
                        Mars color: Claimed
                    </div>
                )}

                <div className={classes.centeredText}>
                    <Button
                        classes={{root: classes.claimButton, label: classes.label}}
                        onClick={() => {
                            setPurchasePlot(getRandomAvailablePlot(claimed));
                        }}
                    >
                        Mint a Plot!
                    </Button>
                </div>
                {isBigScreen &&
				<div className={classes.filler}>Remaining Public Plots: {publicPlotsAvailable}</div>}
            </div>
            {/*<TextareaAutosize id="outlined-basic" onChange={(event) => setText(`${event.target.value}`)}/>*/}
            <ReactMarkdown
                className={isBigScreen ? classes.textBody : classes.textBodySmallScreen}
                rehypePlugins={[rehypeRaw]}
            >
                {HomeBody1}
            </ReactMarkdown>
        </div>
    );
}

export default Home;
